<template>
	<v-container>
		<v-layout row>
			<v-flex xs12>
				<Progress :error="error" :loading="loading" type="overlay" />
				<component v-if="to_pay" :is="accessDeny" />
				<material-card color="info" v-if="video" :title="title">
					<template v-slot:headerRight>
						<router-link class="link-with-effect title" :to="{ name: 'channelPage', params: { address: channel.address }}" v-html="channelTitle"></router-link>
					</template>
					<v-alert
						v-model="adsWarning"
						v-if="video.extra.ads"
						type="warning"
						colored-border
						border="left"
						dismissible
					>В этом видео содержится встроенная реклама. Оно будет перезалито.</v-alert>
					<player :video="video" :section="channel.section" :autoplay="autoplay" />
					<channel-slide :items="list" type="video" strip="season" :active="active"></channel-slide>
					<expand
						v-if="video.extra"
						:description="video.extra.description"
						:description_extra="video.extra.description_extra"
					/>
				</material-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { Helper } from "../mixins/Helper";
import Player from "../components/player.vue";
import Expand from "../components/expand";
import ChannelSlide from "../components/channel-slide.vue";

function fetchData(id) {
	return window.fetchData("/api/video/" + id, {});
}
export default {
	mixins: [Helper],
	components: { Player, Expand, ChannelSlide },
	data() {
		return {
			loading: true,
			error: null,
			video: null,
			autoplay: false,
			channel: null,
			list: null,
			id: null,
			active: 0,
			overlay: false,
			to_pay: false,
			adsWarning: true
		};
	},
	created() {
		let t = this;
		this.id = this.$route.params.id;
		this.autoplay = this.$route.query.autoplay ? true : false;
		document.title = "";
		if (process.env.NODE_ENV != "production") {
			this.$nextTick(() => {
				if (t.loading == true) this.fetchData();
			});
		}
	},
	computed: {
		title() {
			return this.videoTitleFilter(this.video.v_title);
		},
		channelTitle() {
			let re = new RegExp(" / (.*)$", "i");
			return this.channel.title.replace(re, "");
		},
		accessDeny() {
			return () => import("../components/helper/access-deny.vue");
		}
	},
	methods: {
		setData(data) {
			let t = this;
			t.loading = false;

			if (data.error == "pay") {
				t.to_pay = true;
				return;
			}

			if (!data.error) {
				t.video = data.video;
				if (data.channel) t.channel = data.channel;
				if (data.list) t.list = data.list;
				t.active = data.active;
				document.title = t.video.v_title;
			} else {
				t.error = data;
			}
		},
		async fetchData() {
			let t = this;
			t.error = null;
			t.loading = true;

			let data = await fetchData(t.id);
			t.setData(data);
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.id = to.params.id;
		this.autoplay = to.query.autoplay ? true : false;
		this.fetchData();

		next();
	},
	async beforeRouteEnter(to, from, next) {
		let data = await fetchData(to.params.id);

		next(vm => {
			vm.setData(data);
		});
	}
};
</script>
<style scoped>
@media only screen and (min-width: 1264px) {
	.container {
		max-width: 1100px;
	}
}
.title-right .v-btn {
	overflow: hidden;
	max-width: 100%;
}
.container .title {
	color: white;
}
@media only screen and (max-width: 350px) {
	.title-right .v-btn {
		overflow: hidden;
		max-width: 220px;
	}
}
</style>